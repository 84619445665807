import React, {useEffect} from 'react';
import cls from './homePage.module.sass'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComment, faUser, faUsers} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {getUserssData} from "../../employers";
import {fetchUsersData} from "../../employers/ui/employerThunk";
import {getQuestionsData} from "../../questions";
import {fetchQuestionData} from "../../questions";
import {getUsers, telegramUsersThunk} from "../../../features/telegramUsersFetch";
import backUrl from "shared/assests/images/education-bg.png"
// import mainUrl from 'shared/assests/images/certificate-bg.png'
import {useNavigate} from "react-router";
import {getUserName} from "../../../features/login";

export const HomePage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const employersData = useSelector(getUserssData)
    const usersData = useSelector(getUsers);
    const questionData = useSelector(getQuestionsData);
    const userRole = useSelector(getUserName);

    useEffect(() => {
        dispatch(fetchUsersData())
    }, [])

    useEffect(() => {
        dispatch(fetchQuestionData());
    }, []);

    useEffect(() => {
        dispatch(telegramUsersThunk());
    }, []);

    const myStyle = {
        backgroundImage: `url(${backUrl})`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fiex',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    }
    return (
        <div className={cls.main}>
            <div className={cls.main__firstBox}>
                <h1>Asosiy sahifa</h1>
            </div>
            <div className={cls.main__box}>
                {
                    userRole === "admin" ? (
                        <>
                            <div className={cls.main__box__emNumber} style={myStyle}
                                 onClick={() => navigate(`/platform/employers`)}>
                                <div className={cls.main__box__emNumber__iconBox}>
                                    <div className={cls.main__box__emNumber__iconBox__icon}>
                                        <FontAwesomeIcon icon={faUser} style={{fontSize: 40 + 'px'}}/>
                                    </div>
                                </div>
                                <div className={cls.main__box__emNumber__contentBox}>
                                    <h1>Hodimlar soni</h1>
                                    <h2>{employersData.length}</h2>
                                </div>
                            </div>
                            <div className={cls.main__box__quickNumber} style={myStyle}
                                 onClick={() => navigate(`/platform/question`)}>
                                <div className={cls.main__box__quickNumber__iconBox}>
                                    <div className={cls.main__box__quickNumber__iconBox__icon}>
                                        <FontAwesomeIcon icon={faComment} style={{fontSize: 40 + 'px'}}/>
                                    </div>
                                </div>
                                <div className={cls.main__box__quickNumber__contentBox}>
                                    <h1>Qisqa javoblar soni (FAQ)</h1>
                                    <h2>{questionData.length}</h2>
                                </div>
                            </div>


                            <div className={cls.main__box__adminsNumber} style={myStyle}>
                                <h1>Hodimlar</h1>
                                <ul className={cls.main__box__adminsNumber__adminsList}>
                                    {
                                        employersData.map((item, index) => (
                                            <li className={cls.main__box__adminsNumber__adminsList__list}>
                                                {item.name} {item.surname}
                                            </li>
                                        ))
                                    }

                                </ul>
                            </div>
                        </>
                    ) : null
                }
                <div className={cls.main__box__usersNumber} style={myStyle} onClick={() => navigate(`/platform/chat`)}>
                    <div className={cls.main__box__usersNumber__iconBox}>
                        <div className={cls.main__box__usersNumber__iconBox__icon}>
                            <FontAwesomeIcon icon={faUsers} style={{fontSize: 40 + 'px'}}/>
                        </div>
                    </div>
                    <div className={cls.main__box__usersNumber__contentBox}>
                        <h1>Foydalanuvchilar soni</h1>
                        <h2>{usersData.length}</h2>
                    </div>
                </div>

            </div>

        </div>
    );
};