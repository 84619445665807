import React, { useState, useEffect, useRef } from 'react';
import cls from './layout.module.sass';
import { API_FOR_IMG, API_URL } from "shared/api/base";
import { getUserInfoThunk, telegramUsersThunk } from "../../../features/telegramUsersFetch";
import { useDispatch, useSelector } from "react-redux";
import { readMessageThunk } from "../../../features/readMessage";
import { fetchUsersData } from "../../../pages/employers/ui/employerThunk";
import { SimplePopup } from "../../../shared/ui/popup";
import { Button } from "../../../shared/ui/button";
import { getUserStatus, userStatusPatch, userStatusThunk } from "../../../features/userStatus";
import logo from 'shared/assests/images/logo.jpg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faBarChart, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {getUserData} from "../../../features/login/model/loginSlice";

export const Layout2 = ({ selectedChat, userId, selectedAnswer, onId, statusText, name, onBack, onBar, size }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [status, setStatus] = useState(null);
    const chatBoxRef = useRef(null);
    const videoRef = useRef(null);
    const chatSocketRef = useRef(null);
    const lastMessageRef = useRef(null);
    const getStatus = useSelector(getUserStatus);
    const dispatch = useDispatch();
    const messageRefs = useRef({});
    const [highlightedMessageId, setHighlightedMessageId] = useState(null);

    useEffect(() => {
        if (selectedChat) {
            setMessages(selectedChat);
        }
    }, [selectedChat]);

    useEffect(() => {
        function initializeWebSocket() {
            chatSocketRef.current = new WebSocket(`${API_URL}ws/chat/${userId}/`);

            chatSocketRef.current.onopen = function () {
                console.log('WebSocket connection established');
            };

            chatSocketRef.current.onmessage = function (e) {
                const data = JSON.parse(e.data);
                if (data.type === 'chat_message') {
                    const receivedMessage = mapMessageData(data.message);
                    setMessages((prevMessages) => [...prevMessages, receivedMessage]);
                    displayNotification("Yangi xabar kelib tushdi!");
                }
            };

            chatSocketRef.current.onclose = function (e) {
                console.error('Chat ulanishi kutilmaganda uzildi:', e);
                if (!e.wasClean) {
                    console.error('Reconnecting WebSocket...');
                    setTimeout(initializeWebSocket, 5000);
                }
            };

            chatSocketRef.current.onerror = function (error) {
                console.error('WebSocket Error:', error);
            };
        }

        initializeWebSocket();

        return () => {
            if (chatSocketRef.current) {
                chatSocketRef.current.close();
            }
        };
    }, [userId]);


    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    useEffect(() => {
        if (selectedAnswer) {
            setNewMessage(selectedAnswer);
        }
    }, [selectedAnswer]);

    useEffect(() => {
        dispatch(userStatusThunk());
    }, []);

    const myStyle = {
        backgroundImage: `url(${logo})`
    };

    const mapMessageData = (apiMessageData) => {
        if (!apiMessageData) {
            return {};
        }

        const user = apiMessageData?.user || {};
        const photos = apiMessageData?.photos || [];
        const mediaGroupMessages = apiMessageData?.media_group_messages || [];

        const data = {
            id: user.id,
            message_id: user.unread_message_ids
        };
        dispatch(readMessageThunk(data));

        let text = apiMessageData.text || '';
        if (apiMessageData.deleted) {
            text = `--deleted-- ${text}`;
        }

        const currentUserId = userId;

        const isOwner = user.telegram_id === currentUserId;

        return {
            id: apiMessageData.id || null,
            text: text,
            type: apiMessageData.type || 'text',
            user: {
                id: user?.id,
                name: user?.name,
                surname: user.surname || '',
                username: user.username || '',
                telegram_id: user.telegram_id || null,
                conversation: user.conversation || '',
                status: user.status || null,
                unread_messages_count: user.unread_messages_count || 0,
                unread_message_ids: user.unread_message_ids || [],
                owner: !isOwner,
            },
            message_id: apiMessageData.message_id || null,
            date: apiMessageData.date || new Date().toISOString(),
            deleted: apiMessageData.deleted || false,
            is_read: apiMessageData.is_read || false,
            media_group_messages: mediaGroupMessages,
            media_group: apiMessageData.media_group || null,
            photos: photos,
            language: apiMessageData.language || 'text',
            entities: apiMessageData.entities || [],
            reply_id: apiMessageData.reply_id || null,
        };
    };

    const handleInputStatus = async (newStatus) => {
        const data = {
            status: newStatus
        };
        await dispatch(userStatusPatch({ userId: onId, data: data }));
        await dispatch(telegramUsersThunk());
    };

    const handleSendMessage = () => {
        const messageToSend = newMessage.trim();

        if (messageToSend !== '') {
            const formattedMessage = {
                text: messageToSend,
                user: {
                    'telegram_id': userId,
                    'chat_id': userId,
                    'owner': true,
                },
                type: 'text',
                date: new Date().toISOString(),
            };

            // Ensure the WebSocket is connected before sending the message
            if (chatSocketRef.current && chatSocketRef.current.readyState === WebSocket.OPEN) {
                chatSocketRef.current.send(JSON.stringify({
                    'message': formattedMessage
                }));
                setNewMessage('');
            } else {
                console.error('WebSocket is not connected.');
            }
        }
    };


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage();
        }
    };

    const handlePlayPause = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
    };

    const handleScrollToMessage = (messageId) => {
        const targetRef = messageRefs.current[messageId];
        if (targetRef && targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            highlightMessage(messageId);
        }
    };

    const highlightMessage = (messageId) => {
        setHighlightedMessageId(messageId);
        setTimeout(() => {
            setHighlightedMessageId(null);
        }, 2000); // 2 soniya davomida ajratib ko'rsatish
    };


    const renderMessageContent = (message) => {
        if (!message) return null;

        if (!messageRefs.current[message.message_id]) {
            messageRefs.current[message.message_id] = React.createRef();
        }
        const messageRef = messageRefs.current[message.message_id];

        // Function to detect and replace URLs with anchor tags
        const replaceUrlsWithLinks = (text) => {
            const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
            return text.replace(urlRegex, (url) => {
                return `<a href="${url}" target="_blank">${url}</a>`;
            });
        };

        // Safe setting inner HTML
        const createMarkup = (text) => {
            return { __html: replaceUrlsWithLinks(text.replace(/\n/g, '<br>')) };
        };

        let repliedMessage = null;
        if (message?.reply_id) {
            repliedMessage = messages.find(
                (msg) => msg?.message_id === parseInt(message?.reply_id)
            );
        }

        const photo = message?.photos?.map((item) => item?.file_path);
        const repPhoto = repliedMessage?.photos?.map((item) => item.file_path);

        const isHighlighted = highlightedMessageId && highlightedMessageId === message.message_id;

        // Agar xabar o'chirilgan bo'lsa
        if (message.deleted) {
            return (
                <div ref={messageRef} className={`${cls.deletedMessage} ${isHighlighted ? cls.highlighted : ''}`}>
                    <p>---O'chirilgan---</p>
                    <h1>{message?.text}</h1>
                </div>
            );
        }

        // Agar xabar foydalanuvchi tomonidan yuborilgan bo'lsa (owner = true)
        if (message?.user?.owner === true) {
            return (
                <div ref={messageRef} className={`${cls.messageInfoRight} ${isHighlighted ? cls.highlighted : ''}`}>
                    <div className={`${cls.messageText} ${message.type === 'voice' ? null : message.type === 'video_note' ? null : cls.messageTextRight}`}>
                        {
                            message.type === 'photo' ? (
                                <>
                                    <img src={`${API_FOR_IMG}${photo}`} alt="" />
                                    <p dangerouslySetInnerHTML={createMarkup(message?.text)}></p>
                                </>
                            ) : message.type === 'document' ? (
                                <a target={"_blank"} href={`${API_FOR_IMG}${photo}`} download>Download file</a>
                            ) : message.type === 'voice' ? (
                                <audio controls>
                                    <source src={`${API_FOR_IMG}${photo}`} type="audio/ogg" />
                                    Your browser does not support the audio element.
                                </audio>
                            ) : message.type === 'video_note' ? (
                                <video className={cls.video_note} onClick={handlePlayPause} ref={videoRef}>
                                    <source src={`${API_FOR_IMG}${photo}`} type="video/mp4" />
                                </video>
                            ) : message.type === 'video' ? (
                                <video controls>
                                    <source src={`${API_FOR_IMG}${photo}`} type="video/mp4" />
                                </video>
                            ) : repliedMessage ? (
                                <>
                                    <div className={cls.repliedMessage} onClick={() => handleScrollToMessage(repliedMessage.message_id)}>
                                        {
                                            repliedMessage.type === 'text' ? (
                                                <p>{repliedMessage?.text}</p>
                                            ) : repliedMessage.type === 'photo' ? (
                                                <img className={cls.repImg} src={`${API_FOR_IMG}${repPhoto}`} alt="" />
                                            ) : repliedMessage.type === 'video_note' ? (
                                                <video className={cls.repImg}>
                                                    <source src={`${API_FOR_IMG}${repPhoto}`} type="video/mp4" />
                                                </video>
                                            ) : null
                                        }
                                    </div>
                                    <p dangerouslySetInnerHTML={createMarkup(message.text)}></p>
                                </>
                            ) : (
                                <p dangerouslySetInnerHTML={createMarkup(message.text)}></p>
                            )
                        }
                        <small style={{ fontSize: '1rem', alignSelf: 'end' }}>
                            {new Date(message?.date).toLocaleString()}
                        </small>
                    </div>

                    <div className={cls.avatar}>
                        {message?.user?.name?.charAt(0)}
                    </div>
                </div>
            );
        } else {
            return (
                <div ref={messageRef} className={`${cls.messageInfo} ${isHighlighted ? cls.highlighted : ''}`}>
                    <div className={cls.avatar}>
                        {message?.user?.name?.charAt(0)}
                    </div>
                    <div className={`${cls.messageText} ${message.type === 'voice' ? null : message.type === 'video_note' ? null : cls.messageTextLeft}`}>
                        {
                            message.type === 'photo' ? (
                                <>
                                    <img src={`${API_FOR_IMG}${photo}`} alt="" />
                                    <p dangerouslySetInnerHTML={createMarkup(message?.text)}></p>
                                </>
                            ) : message.type === 'document' ? (
                                <a target={"_blank"} href={`${API_FOR_IMG}${photo}`} download>Download file</a>
                            ) : message.type === 'voice' ? (
                                <audio controls>
                                    <source src={`${API_FOR_IMG}${photo}`} type="audio/ogg" />
                                    Your browser does not support the audio element.
                                </audio>
                            ) : message.type === 'video_note' ? (
                                <video className={cls.video_note} onClick={handlePlayPause} ref={videoRef}>
                                    <source src={`${API_FOR_IMG}${photo}`} type="video/mp4" />
                                </video>
                            ) : message.type === 'video' ? (
                                <video controls>
                                    <source src={`${API_FOR_IMG}${photo}`} type="video/mp4" />
                                </video>
                            ) : repliedMessage ? (
                                <>
                                    <div className={cls.repliedMessage} onClick={() => handleScrollToMessage(repliedMessage.message_id)}>
                                        {
                                            repliedMessage.type === 'text' ? (
                                                <p>{repliedMessage?.text}</p>
                                            ) : repliedMessage.type === 'photo' ? (
                                                <img className={cls.repImg} src={`${API_FOR_IMG}${repPhoto}`} alt="" />
                                            ) : repliedMessage.type === 'video_note' ? (
                                                <video className={cls.repImg}>
                                                    <source src={`${API_FOR_IMG}${repPhoto}`} type="video/mp4" />
                                                </video>
                                            ) : null
                                        }
                                    </div>
                                    <p dangerouslySetInnerHTML={createMarkup(message.text)}></p>
                                </>
                            ) : (
                                <p dangerouslySetInnerHTML={createMarkup(message.text)}></p>
                            )
                        }
                        <small style={{ fontSize: '1rem', alignSelf: 'end' }}>
                            {new Date(message?.date).toLocaleString()}
                        </small>
                    </div>

                </div>
            );
        }
    };


    const displayNotification = (message) => {
        if (Notification.permission === "granted") {
            new Notification(message);
        } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(permission => {
                if (permission === "granted") {
                    new Notification(message);
                }
            });
        }
    };

    return (
        <main className={cls.main}>
            <div className={cls.main__content} style={myStyle}>
                <span className={cls.bluredBox}>
                    {selectedChat.length > 0 ? (
                        <div className={cls.chatBox} ref={chatBoxRef} style={myStyle}>
                            <span className={cls.chatBlur}>
                                <div className={cls.profileNav} >
                                    <h2>{name}</h2>
                                    <div className={cls.buttonPanel}>
                                        {
                                            size[0] < 510 ?
                                                <Button extraClass={cls.buttons} onClick={onBack} children="Orqaga" /> :
                                                null
                                        }

                                        <SimplePopup
                                            triggerContent={
                                                <div>
                                                    <Button extraClass={cls.statusBtn} children={!statusText ? <h3>Status</h3> : statusText} />
                                                </div>
                                            }
                                            popupContent={
                                                <div className={cls.box}>
                                                    {
                                                        getStatus.map((item) => (
                                                            <span className={cls.popupMain} onClick={() => {
                                                                setStatus(item.id);
                                                                handleInputStatus(item.id);
                                                            }}>
                                                                <h2>{item.name}</h2>
                                                            </span>
                                                        ))
                                                    }
                                                </div>
                                            }
                                        />
                                        {
                                            size[0] < 510 ?
                                                <Button extraClass={cls.buttons} onClick={onBar} children={<FontAwesomeIcon icon={faBarChart} />} /> :
                                                null
                                        }
                                    </div>

                                </div>
                                <div className={cls.messages}>
                                    {messages?.map((message, index) => (
                                        <div
                                            key={index}
                                            className={cls.message}
                                            ref={index === messages.length - 1 ? lastMessageRef : null}
                                        >
                                            {renderMessageContent(message)}
                                        </div>
                                    ))}
                                </div>
                                <div className={cls.messageInputBox}>
                                    <textarea
                                        cols="50"
                                        placeholder="Xabar"
                                        value={newMessage}
                                        onChange={(e) => setNewMessage(e.target.value)}
                                        className={cls.messageInput}
                                        onKeyDown={handleKeyDown}
                                    />
                                    <button onClick={handleSendMessage} className={cls.sendButton}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={cls.sendIcon}>
                                            <path d="M2 21l21-9L2 3v7l15 2-15 2v7z" />
                                        </svg>
                                    </button>
                                </div>
                            </span>

                        </div>
                    ) : (
                        <p className={cls.warning}>Chatni ochish uchun 2 marta bosing</p>
                    )}
                </span>

            </div>
        </main>
    );
};
