import React, {useState} from 'react';
import cls from './chatList.module.sass';
import { getFiltered } from "../../../pages/employers";
import { useSelector } from "react-redux";

export const ChatList = ({ currentTableData, onSelectChat, onUserId, onId, setStatusText, setName}) => {
    const getFilrerUser = useSelector(getFiltered);
    // const [names, setNames] = useState('')
    //
    // console.log(names)


    const dataToMap = getFilrerUser.length > 0 ? getFilrerUser : currentTableData;

    return dataToMap.map((item, index) => (
        <div
            key={index}
            className={cls.profileBox}
            onClick={() => {
                onSelectChat(item);
                onUserId(item.telegram_id);
                onId(item.id);
                setStatusText(item.status?.name)
                setName(item?.name)
            }}
        >
            <div className={cls.profileBox_imgBox}>
                <span className={cls.profileBox_imgBox_img}>
                    {item?.name.slice(0, 1)}
                </span>
                <span style={{ background: `${item.status?.color}` }} className={cls.statusCircle}>
                </span>
            </div>
            <div className={cls.profileBox_usernameBox}>
                <h1 className={cls.profileBox_usernameBox_username}>
                    {item?.name}
                    <b style={{textDecoration: 'underline'}}>→{item?.admin?.name}</b>
                </h1>
            </div>
            <div className={cls.profileBox_dateBox}>
                <h3 className={cls.profileBox_dateBox_date}>
                    {item.unread_messages_count}
                </h3>
            </div>
        </div>
    ));
};
